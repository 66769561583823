import React, { useEffect, useState } from 'react';
import { Collapse, Icon, Table, Tag, Typography, Row, Button } from 'antd';
import moment from 'moment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
// interfaces
import {
  Box,
  ReplenishmentRequest,
  ReplenishmentShipment,
} from '../../../../interfaces/replenishment-request';
import {
  ReplenishmentSteps,
  ShipmentLTLSteps,
  ShipmentType,
} from '../../../../enums/replenishment-request.enums';
// components
import Spacer from '../../../../components/spacer';
// utils
import { labelOptions } from '../../../../utils/marketplace';
import { isArray } from '../../../../utils';

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

interface DisplayCompletedStepsSummaryProps {
  replenishment: ReplenishmentRequest;
}

export default function DisplayCompletedStepsSummary({
  replenishment,
}: DisplayCompletedStepsSummaryProps): JSX.Element {
  const [completedSteps, setCompletedSteps] = useState<JSX.Element>(<></>);

  function logisticsBodyParser(shipment: any, id: string) {
    return shipment.boxes.map((box: any, index: number) => {
      return [
        id,
        index + 1,
        box.products.map((product: any) => {
          return `${product.qty} units of ${product.seller_sku} 
           ${
             product.expirationDate
               ? ' expiring on: ' + moment(product.expirationDate).format('MM-DD-YYYY')
               : ' '
           }`;
        }),
        shipment.isHazmat && shipment.submittedTrackingNumbers
          ? `${
              isArray(shipment.trackingNumbers)
                ? shipment.trackingNumbers[index]
                : shipment.trackingNumbers
            }`
          : ' ',
        box.weight ? box.weight + ' lbs' : 'N/A',
        box.dimensions ? `${box.dimensions.x}x${box.dimensions.y}x${box.dimensions.z}` : ' ',
      ];
    });
  }

  function downloadLogisticsPdf(pdfName: string, body: any[]) {
    const doc = new jsPDF('landscape');

    autoTable(doc, {
      head: [['Shipment ID', 'Box #', 'Box content', 'Tracking Numbers', 'Weight', 'Dimensions']],
      body,
    });

    doc.save(`${pdfName}.pdf`);

    return doc;
  }

  function exportAllLogisticsAsPdf(shipments: any) {
    const body = [];

    for (const shipmentId in shipments) {
      const shipment = shipments[shipmentId];

      body.push(...logisticsBodyParser(shipment, shipmentId));
    }

    return downloadLogisticsPdf('logistics', body);
  }

  function exportLogisticsAsPdf(shipment: any, id: string) {
    const body = logisticsBodyParser(shipment, id);

    return downloadLogisticsPdf(id, body);
  }

  useEffect((): void => {
    function getCompletedRows(): void {
      const completedRows: JSX.Element[] = [];

      function pushRowTitleAndContent(): (title: string, content: JSX.Element) => void {
        let counter = 0;
        return function(title: string, content: JSX.Element): void {
          completedRows.push(
            <Panel
              key={`completed-${counter}`}
              header={
                <div style={{ display: 'flex' }}>
                  <Tag color="green">COMPLETED</Tag>
                  <Spacer width={7} />
                  <Text>{title}</Text>
                </div>
              }
            >
              {content}
            </Panel>,
          );
          counter++;
        };
      }

      if (!replenishment.step) return;

      const pushStep = pushRowTitleAndContent();

      if (replenishment.step > ReplenishmentSteps.ConfirmQuantities) {
        pushStep(
          'Confirm quantities requested by Sunken Stone Manager',
          <Table
            dataSource={replenishment.products}
            columns={[
              {
                title: 'Image',
                dataIndex: 'product_image_url',
                key: 'product_image_url',
                render: (text: string): JSX.Element => (
                  <img style={{ maxWidth: '75px', maxHeight: '75px' }} src={text} />
                ),
              },
              { title: 'SKU', dataIndex: 'seller_sku', key: 'sku' },
              {
                title: 'Requested Qty',
                dataIndex: 'requestedQty',
                key: 'requestedQty',
                render: (text: string): JSX.Element => <Text>{text}</Text>,
              },
              {
                title: 'Available Qty',
                key: 'availableQty',
                dataIndex: 'availableQty',
                render: (text: string): JSX.Element => <Text>{text}</Text>,
              },
            ]}
          />,
        );
      }

      if (replenishment.step > ReplenishmentSteps.ApproveQuantities) {
        pushStep(
          'Quantities approved',
          <Text>The quantities were approved by a Sunken Stone Manager</Text>,
        );
      }

      if (replenishment.step > ReplenishmentSteps.ConfirmProductLabels) {
        pushStep('Confirmed product labels', <Text>The product labels are all set</Text>);
      }

      if (replenishment.step > ReplenishmentSteps.SelectPackingAndAddress) {
        const { shipFrom } = replenishment;
        if (shipFrom) {
          pushStep(
            'Packing Type and Shipping Address',
            <>
              <Paragraph>
                <Text strong>Selected Address: </Text>{' '}
                <Text>
                  {shipFrom.street_one}
                  {shipFrom.street_two && ` ${shipFrom.street_two}`}. {shipFrom.city},{' '}
                  {shipFrom.state.state_code}. {shipFrom.country.country_code}
                </Text>
              </Paragraph>
              <Paragraph>
                <Text strong>Packing Type: </Text>
                <Text>
                  {replenishment.AreCasesRequired ? 'Case-Packed' : 'Individually-Packed'}
                </Text>
              </Paragraph>
            </>,
          );
        }
      }

      if (replenishment.step > ReplenishmentSteps.AddLogisticsInfo) {
        const shipments = [];
        for (const shipmentId in replenishment.shipments) {
          const shipment = replenishment.shipments[shipmentId];
          const pageType = labelOptions.find((label): boolean => label.value === shipment.pageType);

          shipments.push(
            <Collapse
              key={shipmentId}
              expandIcon={({ isActive }): JSX.Element => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
              style={{ marginTop: 10, marginBottom: 40 }}
            >
              <Panel header={`Shipment ${shipmentId}`} key={shipmentId}>
                {shipment.shipmentType === ShipmentType.LTL &&
                  shipment.step &&
                  shipment.step > ShipmentLTLSteps.SubmitPalletInfo && (
                    <>
                      <Paragraph>
                        <Text strong>Freight ready date:</Text>{' '}
                        <Text>{moment(shipment.freightReadyDate).format('MM-DD-YYYY')}</Text>
                      </Paragraph>

                      <Title style={{ fontSize: 14 }}>Pallet Information</Title>
                      <Table
                        columns={[
                          { title: '# of pallets', dataIndex: 'numberOfPallets' },
                          {
                            title: 'Dimensions (in.)',
                            dataIndex: 'height',
                            render: (height: number): JSX.Element => <Text>48x40x{height}</Text>,
                          },
                          {
                            title: 'Weight (lb)',
                            dataIndex: 'weight',
                            render: (weight: number): JSX.Element => <Text>{weight} lbs</Text>,
                          },
                        ]}
                        dataSource={shipment.pallets}
                      />
                      <Spacer height={10} />
                    </>
                  )}
                {pageType && (
                  <Paragraph>
                    <Text strong>Page Type: </Text>
                    <Text>{pageType.label}</Text>
                  </Paragraph>
                )}
                <Spacer height={10} />
                <Row type="flex" justify="space-between">
                  <Title style={{ fontSize: 14 }}>Boxes</Title>
                  <Button
                    download
                    icon="download"
                    type="primary"
                    onClick={() => exportLogisticsAsPdf(shipment, shipmentId)}
                  >
                    Export as PDF
                  </Button>
                </Row>

                <Table
                  dataSource={shipment.boxes}
                  columns={[
                    {
                      title: 'Box #',
                      render: (text, record): JSX.Element => (
                        <Text>
                          {shipment.boxes.findIndex((item: Box): boolean => record === item) + 1}
                        </Text>
                      ),
                    },
                    {
                      title: 'Box content',
                      render: (record): JSX.Element => (
                        <>
                          {record.products.map(
                            (product: any): JSX.Element => (
                              <Text key={product.seller_sku}>
                                {product.qty} units of {product.seller_sku}
                                {product.expirationDate &&
                                  ` expiring on: ${moment(product.expirationDate).format(
                                    'MM-DD-YYYY',
                                  )}`}{' '}
                                <br />
                              </Text>
                            ),
                          )}
                        </>
                      ),
                    },
                    ...(shipment.isHazmat && shipment.submittedTrackingNumbers
                      ? [
                          {
                            title: 'Tracking numbers',
                            render: (record: any, record2: any, index: number): JSX.Element => {
                              const trackingNumber = isArray(shipment.trackingNumbers)
                                ? shipment.trackingNumbers[index]
                                : shipment.trackingNumbers;
                              return <Text>{trackingNumber}</Text>;
                            },
                          },
                        ]
                      : []),
                    {
                      title: 'Weight',
                      dataIndex: 'weight',
                      render: (text): JSX.Element =>
                        text ? <Text>{text} lbs</Text> : <Text>N/A</Text>,
                    },
                    {
                      title: 'Dimensions',
                      render: (record): JSX.Element =>
                        record.dimensions ? (
                          <Text>
                            {record.dimensions.x}x{record.dimensions.y}x{record.dimensions.z}
                          </Text>
                        ) : (
                          <Text>N/A</Text>
                        ),
                    },
                  ]}
                />
              </Panel>
            </Collapse>,
          );
        }

        pushStep(
          'Received logistics information',
          <>
            <Row type="flex" justify="space-between">
              <Button
                download
                icon="download"
                type="primary"
                onClick={() => exportAllLogisticsAsPdf(replenishment.shipments)}
              >
                Export All as PDF
              </Button>
            </Row>
            {shipments}
          </>,
        );
      }

      if (replenishment.step > ReplenishmentSteps.UploadFBALabels) {
        pushStep(
          'Upload boxes verification photos',
          <>
            <Text>The verification photos were uploaded by {replenishment.brand.name}</Text>
          </>,
        );
      }

      if (replenishment.step > ReplenishmentSteps.ApproveOrRejectLabels) {
        pushStep(
          'Approve verification photos',
          <>
            <Text>The verification photos were approved by a Sunken Stone Manager</Text>
          </>,
        );
      }

      function makeLink(url?: string): JSX.Element {
        if (!url) return <Text />;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        );
      }

      function getShipmentLabels(shipment: ReplenishmentShipment): JSX.Element {
        if (shipment.shipmentType === ShipmentType.SP) {
          if (shipment.isHazmat) {
            return <Text>No UPS Labels</Text>;
          } else {
            return (
              <>
                <Paragraph>
                  <Text>UPS Labels: {makeLink(shipment.upsLabels)}</Text>
                </Paragraph>
                {shipment.upsThermalLabels && (
                  <Paragraph>
                    <Text>UPS Thermal Labels: {makeLink(shipment.upsThermalLabels)}</Text>
                  </Paragraph>
                )}
              </>
            );
          }
        } else {
          return (
            <>
              <Paragraph>
                <Text>Pallet Labels: {makeLink(shipment.palletLabels)}</Text>
              </Paragraph>
              {shipment.palletThermalLabels && (
                <Paragraph>
                  Pallet Thermal Labels: {makeLink(shipment.palletThermalLabels)}
                </Paragraph>
              )}
            </>
          );
        }
      }

      if (replenishment.step > ReplenishmentSteps.GetUpsOrPalletLabels) {
        const shipmentIds = Object.keys(replenishment.shipments);
        const hazmatShipments = shipmentIds.filter((shipmentId: string): boolean => {
          return replenishment.shipments[shipmentId].isHazmat;
        });

        const labels = [];
        let totalLTLShipments = 0;

        for (const shipmentId in replenishment.shipments) {
          const shipment = replenishment.shipments[shipmentId];
          if (shipment.shipmentType === ShipmentType.LTL) {
            totalLTLShipments++;
          }

          labels.push(
            <Collapse
              key={`${shipmentId}-labels`}
              expandIcon={({ isActive }): JSX.Element => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
              style={{ marginTop: 10, marginBottom: 40 }}
            >
              <Panel header={`Labels for shipment ${shipmentId}`} key={shipmentId}>
                {shipment.fbaLabels && (
                  <Paragraph>FBA Labels: {makeLink(shipment.fbaLabels)}</Paragraph>
                )}
                {shipment.fbaThermalLabels && (
                  <Paragraph>FBA Thermal Labels: {makeLink(shipment.fbaThermalLabels)}</Paragraph>
                )}
                {getShipmentLabels(shipment)}
              </Panel>
            </Collapse>,
          );
        }

        if (hazmatShipments.length !== shipmentIds.length) {
          let title: string;

          if (totalLTLShipments === shipmentIds.length) {
            title = 'Get Pallet Labels';
          } else if (totalLTLShipments > 0) {
            title = 'Get UPS or Pallet Labels';
          } else {
            title = 'Get UPS Labels';
          }

          pushStep(title, <>{labels}</>);
        }
      }

      setCompletedSteps(<Collapse>{completedRows.reverse()}</Collapse>);
    }

    getCompletedRows();
  }, [replenishment]);

  return (
    <div style={{ background: 'white' }}>
      <Title style={{ fontSize: 24 }}>Completed steps</Title>

      {completedSteps}
    </div>
  );
}
