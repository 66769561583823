import React from 'react';
import { Spin, Typography, Alert, Row, Button, message } from 'antd';
import * as H from 'history';
// interfaces
import { ReplenishmentRequest } from '../../../../interfaces/replenishment-request';
// utils
import { checkUserBelongsToRoles } from '../../../../utils';
// enums
import { ReplenishmentSteps } from '../../../../enums/replenishment-request.enums';
// components
import ConfirmQuantities from '../components/confirm-quantities';
import ApproveQuantities from '../components/approve-quantities';
import ReplenishmentItemLabels from '../components/item-labels';
import ShippingPlanForm from '../components/shipping-plan-form';
import DisplayShipments from '../components/display-shipments';
import UploadFBALabels from '../components/upload-fba-labels';
import UploadTrackingOrProNumbers from '../components/upload-tracking-numbers';
import DisplayShipmentsPhotos from '../components/display-shipments-photos';
import ReplenishmentStatusTag from '../components/status-tag';
import GetUpsOrPalletLabels from '../components/get-ups-or-pallet-labels';
import DisplayManagersLastStep from '../components/display-managers-last-steps';
import AdditionalSteps from '../components/additional-steps';
import { ApiWithToken } from '../../../../utils/api';

const checkUserRoles = checkUserBelongsToRoles();
const { Text } = Typography;

interface DisplayShipmentStepsProps {
  replenishment: ReplenishmentRequest;
  fetchReplenishment: Function;
  loading: boolean;
  setLoading: Function;
  history: H.History;
}

export default function DisplayShipmentSteps({
  replenishment,
  fetchReplenishment,
  loading,
  setLoading,
  history,
}: DisplayShipmentStepsProps): JSX.Element | null {
  const shipmentIds: any[] =
    replenishment.hasOwnProperty('shipments') && replenishment.shipments
      ? Object.keys(replenishment.shipments)
      : [];

  async function downloadLabels(): Promise<void> {
    for (const shipmentId of shipmentIds) {
      try {
        let shipment = replenishment.shipments[shipmentId];
        if (!shipment.upsLabels && !shipment.palletLabels) {
          message.error(`No available labels for shipment ${shipmentId}`);
        } else {
          await ApiWithToken.post(
            `/replenishments/${replenishment &&
              replenishment._id}/mark-shipment-finished/shipment/${shipmentId}`,
          );

          let a = document.createElement('a');
          a.href = `${shipment.upsLabels || shipment.palletLabels}`;
          a.target = '_blank';
          a.rel = 'noopener noreferrer';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          fetchReplenishment();
        }
      } catch (e) {
        message.error('Something went wrong downloading the labels');
      }
    }
  }

  function checkReplenishmentStep(): JSX.Element | null {
    switch (replenishment.step) {
      case ReplenishmentSteps.ConfirmQuantities:
        return (
          <ConfirmQuantities
            replenishment={replenishment}
            fetchReplenishment={fetchReplenishment}
            loading={loading}
            setLoading={setLoading}
            history={history}
          />
        );

      case ReplenishmentSteps.ApproveQuantities:
        return checkUserRoles(['admin', 'manager']) ? (
          <ApproveQuantities
            replenishment={replenishment}
            fetchReplenishment={fetchReplenishment}
            setLoading={setLoading}
          />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ReplenishmentStatusTag replenishment={replenishment} />
          </div>
        );

      case ReplenishmentSteps.ConfirmProductLabels:
        return checkUserRoles(['admin', 'manager']) ? (
          <>
            <Spin style={{ marginRight: 10 }} />
            <Text>
              Waiting for {(replenishment && replenishment.brand.name) || 'brand'} to confirm labels
            </Text>
          </>
        ) : (
          <ReplenishmentItemLabels
            replenishment={replenishment}
            fetchReplenishment={(): void => fetchReplenishment()}
          />
        );

      case ReplenishmentSteps.SelectPackingAndAddress:
        return checkUserRoles(['admin', 'manager']) ? (
          <>
            <Spin style={{ marginRight: 10 }} />
            <Text>
              Waiting for {(replenishment && replenishment.brand.name) || 'brand'} to select packing
              type and shipping address
            </Text>
          </>
        ) : (
          <ShippingPlanForm
            fetchReplenishment={(): void => fetchReplenishment()}
            replenishment={replenishment}
            history={history}
          />
        );

      case ReplenishmentSteps.AddLogisticsInfo:
        return checkUserRoles(['admin', 'manager']) ? (
          <>
            <Spin style={{ marginRight: 10 }} />
            <Text>
              Waiting for {(replenishment && replenishment.brand.name) || 'brand'} to give logistics
              information
            </Text>
          </>
        ) : (
          <DisplayShipments
            fetchReplenishment={(): void => fetchReplenishment()}
            replenishment={replenishment}
          />
        );

      case ReplenishmentSteps.UploadFBALabels:
        return checkUserRoles(['admin', 'manager']) ? (
          <>
            <Spin style={{ marginRight: 10 }} />
            <Text>
              Waiting for {(replenishment && replenishment.brand.name) || 'brand'} to upload labels
            </Text>
          </>
        ) : (
          <UploadFBALabels
            fetchReplenishment={(): void => fetchReplenishment()}
            replenishment={replenishment}
          />
        );

      case ReplenishmentSteps.UploadTrackingNumbers:
        return checkUserRoles(['admin', 'manager']) ? (
          <>
            <Spin style={{ marginRight: 10 }} />
            <Text>
              Waiting for {(replenishment && replenishment.brand.name) || 'brand'} to upload
              tracking numbers
            </Text>
          </>
        ) : (
          <UploadTrackingOrProNumbers
            fetchReplenishment={(): void => fetchReplenishment()}
            replenishment={replenishment}
          />
        );

      case ReplenishmentSteps.ApproveOrRejectLabels:
        return checkUserRoles(['admin', 'manager']) ? (
          <DisplayShipmentsPhotos
            replenishment={replenishment}
            fetchReplenishment={(): void => fetchReplenishment()}
          />
        ) : (
          <div style={{ marginTop: 20 }}>
            <Spin style={{ marginRight: 10 }} /> <Text>Waiting for Sunken Stone approval</Text>
          </div>
        );

      case ReplenishmentSteps.GetUpsOrPalletLabels:
        return checkUserRoles(['admin', 'manager']) ? (
          <DisplayManagersLastStep replenishment={replenishment} />
        ) : (
          <div style={{ marginTop: 20 }}>
            <Row type="flex" justify="space-between">
              <Button download icon="download" type="primary" onClick={() => downloadLabels()}>
                Download All Labels
              </Button>
            </Row>
            <GetUpsOrPalletLabels
              replenishment={replenishment}
              fetchReplenishment={fetchReplenishment}
            />
          </div>
        );

      case ReplenishmentSteps.Finished:
        return checkUserRoles(['admin', 'manager']) ? (
          <DisplayManagersLastStep replenishment={replenishment} />
        ) : (
          <Alert
            type="success"
            style={{ marginTop: 20 }}
            showIcon
            message="This replenishment request was finished successfully."
          />
        );

      case ReplenishmentSteps.AdditionalSteps:
        return checkUserRoles(['admin', 'manager']) ? (
          <AdditionalSteps replenishment={replenishment} />
        ) : (
          <Alert
            type="success"
            style={{ marginTop: 20 }}
            showIcon
            message="This replenishment request was finished successfully."
          />
        );
    }

    return null;
  }

  return <>{checkReplenishmentStep()}</>;
}
